.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slick-prev:before, .slick-next:before {
 
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #312c2c !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.welcome-area {
  padding-bottom: 60px !important;
  padding-top: 90px !important;
}
.dir-img {
  border: 1px solid #eee;
  margin: 5px;
  text-align: center;
  padding: 10px 0;
  background: #fff;
  box-shadow: 1px 1px 6px #eee;
  padding: 5px
}
.dir-img {
  position: relative;
}
.working-area .sec-title {
  padding: 0 0px 20px;
}

@media only screen and (max-width: 768px) {
  .topheader{
    display: none !important;
  }
  .wid{
    width: 300px !important;
  }
  .pd{
  padding:10px  
  }
  .fsd{
    font-size: 10px;
  }
  .fs{
    font-size: 10px;
  }
  .bg-cl-1 {
    background: #25A241;
    padding: 5px;
}

}
@media only screen and (min-width: 768px) {
  .hide1{
    display:none
  }
  .wid{
    width: 600px !important;
  }
  .pd{
  padding:10px  
  }
  .fsd{
    font-size: 15px;
  }.fs{
    font-size: 35px;

  }
  .bg-cl-1 {
    background: #25A241;
    padding: 10px;
}

}



.active1{
  background-color: #6aec6a;
  width:fit-content;
  padding:0 5px;
  border-radius: 10px;
}

.pricing-table-area {
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}
.working-area {
  padding-top: 60px !important;
  padding-bottom: 50px !important;
}

.latest-project-area {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    overflow: hidden;
}
.welcome-area {
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}
.account-area .form form {
  background: #c7c4c4 none repeat scroll 0 0;
  border: 1px solid #f3f3f3;
  padding: 28px 34px 34px;
}
.hide{
  display:none
}
.show{
  display:block
}
#blog-area {
  padding-bottom: 100px;
  padding-top: 10px;
}
.bgc{
 
  background-color: rgb(199,196,196);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 15px;
}


*{
  margin: 0;
  padding: 0;
}
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position:absolute;
  top:-9999px;
}
.rate:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */
 

.container1 {
  position: relative;
  text-align: center;
  color: white;
}

/* .bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
} */
@media only screen and (max-width: 600px) {
  .centered1 {
    position: absolute;
    top: 92%;
    left: 18%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (min-width: 600px) {
  .centered1 {
    position: absolute;
    top: 92%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
}

/* p{
  color: black;
} */
.text-color{
  color:black
}

 input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border:solid 1px !important;

}
/*modal */
@media only screen and (max-width: 600px) {
  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal1 {
    background: white !important;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    position:absolute;
    z-index: 999999;

  }
}
@media only screen and (min-width: 600px) {
  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal1 {
    background: white !important;
    max-width: 500px;
    width: 40%;
    border-radius: 10px;
    position:absolute;
    z-index: 999999;

  }
}
@media only screen and (max-width: 600px) {
  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal {
    background: black !important;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    

  }
  .paddingTop{
    padding-top: 35px !important;
  }
  .couponbutton{
    margin-left: 39%;
    margin-top: 2%;
  }
}
@media only screen and (min-width: 600px) {
 
  .customModal {
    background: black !important;
    max-width: 500px;
    width: 40%;
    border-radius: 10px;

  }
}
@media only screen and (max-width: 600px) {
  .customOverlay1 {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal2 {
    background: #075d25 !important;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .customOverlay1 {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal2 {
    background: #075d25 !important;
    max-width: 500px;
    width: 40%;
    border-radius: 10px;
  }
}

/* svg {
  
  background-color: red!important;
} */

.react-responsive-modal-closeButton{
  background-color: white !important;
  }

  /* Traking */


  *, *:after, *:before { margin:0; padding:0; }


.node {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display:inline-block;
    transition: all 1000ms ease;
}

.activated {
    box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
}

.divider {
    height: 40px;
    width: 2px;
    margin-left: 4px;
    transition: all 800ms ease;
}

li p {
    display:inline-block;
    margin-left: 25px;
}

li {
    list-style: none;
    /* line-height: 1px; */
}


.blue { background-color: rgba(82, 165, 255, 1); }
.green{ background-color: rgba(92, 184, 92, 1) }
.red { background-color: rgba(255, 148, 148, 1); }
.grey { background-color: rgba(201, 201, 201, 1); }
  /* End Traking */
.black{
  color: black !important;
}
.white11{
  color: white !important;
}
.buttonresize{
    padding-left: 40%;
    padding-right: 40%;
}
#polyglotLanguageSwitcher * {
  margin: 5px !important;
  padding: 0;
  outline: none;
}
.futterPading{
  padding-top: 25px !important;
}
.searchbackgroundcolor{
  background-color: palegoldenrod !important;
}











.fade-appear,
.fade-enter{
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active{
  opacity: 1;
  transition: opacity 5000 ms linear 1500ms;
}
.fade-exit{
  opacity: 1;
}
.fade-exit.fade-exit-active{
  opacity: 0;
  transition: opacity 1500ms linear;
}
@media (max-width: 700px) {
  #tawkchat-minified-box.round {
      display:none;
  }
}
.image_redious{
  border-radius: 50% !important;
  height: 165px !important;
  width: 165px !important;
  padding: 9px;
  object-fit: cover;
}
.image_rectangle{
  border-radius: 10% !important;
  height: 165px !important;
  width: 165px !important;
  padding: 9px;
  object-fit: cover;
}
.fonn_sizell{
  font-size: 15px;
/* text-align: justify; */
}
.cc:hover{
  box-shadow: 8px 8px 8px 8px #888888;
  cursor: pointer;
  height: 330px;
}

@media only screen and (max-width: 670px) {
  .lefservice {
    padding-left:90px;
  }
  .frontsize{
    font-size: 15px !important;
  }
 
}
@media only screen and (min-width: 670px) {

.frontsize{
  font-size: 50px !important;
}
}


.paginationBttns{
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  /* // justify-content: center; */
}
.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid rgb(37,211,102);
  color: rgb(37,211,102);
  cursor: pointer;
}

.paginationBttns a:hover{
  color: white;
  background-color:rgb(37,211,102); 
}
.paginationActive a{
  color: white;
  background-color:rgb(37,211,102); 
}
.cp{
  cursor: pointer;
}
.abc11{
  margin: 18px;
/* padding-bottom: ; */
}